@use '@angular/material' as mat;
@use 'sass:map';
@mixin styles-reset-theme($theme) {
    
    $primary: map.get($theme, primary);
    $accent:  map.get($theme, accent);
    $bg:      map.get($theme, background);
    $fg:      map.get($theme, foreground);

    $background: map.get(map.get($theme, background), app-background);
    $md-default-button: map.get(map.get($theme, background), md-default-button);
    $md-button-outstanding: map.get(map.get($theme, foreground), md-button-outstanding);
    $md-popup-card: map.get(map.get($theme, background), md-popup-card);
    $md-sidenav-backdrop: map.get(map.get($theme, background), md-sidenav-backdrop);
    $md-slide-button-bg: map.get(map.get($theme, background), md-slide-button-bg);  
    $md-slide-button-fg: map.get(map.get($theme, foreground), md-slide-button-fg);
    $md-tabs-text: map.get(map.get($theme, foreground), md-tabs-text);
    $md-as-split-bg: map.get(map.get($theme, background), md-as-split-bg);
    $md-popup-header-bg: map.get(map.get($theme, background), md-popup-header-bg);
    $md-popup-header-color: map.get(map.get($theme, background), md-popup-header-color);
    $md-popup-header-border: map.get(map.get($theme, background), md-popup-header-border);
    $md-popup-header-closeicon-bg: map.get(map.get($theme, background), md-popup-header-closeicon-bg);
    $md-popup-header-closeicon-fg: map.get(map.get($theme, foreground), md-popup-header-closeicon-fg);
    $md-as-split-border: map.get(map.get($theme, foreground), md-as-split-border);
    $md-warning-icon: map.get(map.get($theme, foreground), md-warning-icon);

    
    /* CUSTOM
    =========== */

    /* Buttons 
    ----------- */

    .md-button[disabled] {
        color: mat.m2-get-color-from-palette($fg, disabled);
        border: 2px solid mat.m2-get-color-from-palette($fg, disabled); 
        background: none;
        pointer-events: none;
    }

    .md-button {
        color: mat.m2-get-color-from-palette($fg, base);
        border: 2px solid mat.m2-get-color-from-palette($fg, base); 
    }

    .md-button-disabled {
        color: mat.m2-get-color-from-palette($fg, disabled);
        border: 2px solid mat.m2-get-color-from-palette($fg, disabled); 
    }

    .md-button-highlighted {
        color: mat.m2-get-color-from-palette($accent);
    }

    .md-button-outstanding {
        color: $md-button-outstanding;
        border: 2px solid mat.m2-get-color-from-palette($accent);
        background: mat.m2-get-color-from-palette($accent);
    }

    .md-button:hover {
        color: mat.m2-get-color-from-palette($accent);
        border: 2px solid mat.m2-get-color-from-palette($accent);
        background: none;
    }

    .md-button:active {
        transform: scale(1.015);
    }
    
    .md-textbutton[disabled] {
        color: mat.m2-get-color-from-palette($fg, disabled) !important;
        pointer-events: none;
        .mat-icon {
            color: mat.m2-get-color-from-palette($fg, disabled);
            pointer-events: none !important;
        }
    }

    .md-textbutton {
        color: mat.m2-get-color-from-palette($fg, base);
        .mat-icon {
            color: mat.m2-get-color-from-palette($fg, base);
        }
    } 
    
    .md-textbutton-highlighted {
        color: mat.m2-get-color-from-palette($accent);
        .mat-icon {
            color: mat.m2-get-color-from-palette($accent);
        }
    } 
    
    .md-textbutton:hover {
        color: mat.m2-get-color-from-palette($accent);
        .mat-icon {
            color: mat.m2-get-color-from-palette($accent);
        }
    }

    /* Popups 
    ---------- */

    .md-popup-header {
        border-bottom-color: $md-popup-header-border;
        background-color: $md-popup-header-bg;
        color: $md-popup-header-color;
        .title{
            color: $md-popup-header-color;
        }
        .mat-icon {
            background-color: $md-popup-header-closeicon-bg;
            color: $md-popup-header-closeicon-fg;
        }
    }

    .md-popup-content {
        color: mat.m2-get-color-from-palette($fg, base);
        .md-popup-content--title {
            color: mat.m2-get-color-from-palette($primary, 600);
        }
    }

    /* Errors 
    ---------- */

    .md-error {
        border: 2px solid mat.m2-get-color-from-palette($primary, 900);
        .mat-icon {
            color: red !important;
            cursor: default;
        }
    }

    .md-warning {
        border: 2px solid mat.m2-get-color-from-palette($primary, 900);
        .mat-icon {
            color: $md-warning-icon !important;
            cursor: default;
        }
    }


    /* ANGULAR MATERIAL
    ==================== */

    /* Sidenav 
    ------------ */

    .mat-drawer-backdrop.mat-drawer-shown {
        background: $md-sidenav-backdrop !important;
        opacity: .6;
    }


    /* Tabs 
    --------- */

    .frame .appbar .appbar-icons .mat-icon.active {
        color: mat.m2-get-color-from-palette($primary, 800) !important;
        background: none !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-mdc-tab, 
    .mat-mdc-tab-link {
       color: $md-tabs-text !important;
    }  


    /* Accordion 
    ------------- */

    .mat-expansion-panel {
        .md-paneltitle, .title {
            border-bottom: 1px solid mat.m2-get-color-from-palette($primary, 900) !important;
        }
        .mat-expansion-indicator::after {
            color: mat.m2-get-color-from-palette($primary, 900) !important;
        }
    }

    /* Buttons 
    ----------- */

    .mat-mdc-button {
        color: mat.m2-get-color-from-palette($fg, base) !important;
        background-color: $md-default-button !important;
    }

    .mat-mdc-mini-fab {
        color: mat.m2-get-color-from-palette($fg, base) !important;
    }

    /* Icons - Buttons 
    ------------------- */

    .mat-icon.disabled {
        color: mat.m2-get-color-from-palette($fg, disabled) !important;
    }
    .mat-icon {
        color: mat.m2-get-color-from-palette($fg, icons);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-bar {
        background-color: $md-slide-button-bg !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-thumb {
        background-color: $md-slide-button-fg !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: mat.m2-get-color-from-palette($accent) !important;
    }

    /* Popups 
    ---------- */

    .md-popup .mat-mdc-card {
        background-color: $md-popup-card !important;
    }


    /* Dropdowns 
    ------------- */
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-search-panel {
        /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
        .mat-mdc-option.mat-selected:not(.mat-option-disabled),
        .mat-mdc-checkbox, .mat-select-search-no-entries-found,
        .firstoption {
            color: mat.m2-get-color-from-palette($primary, 900) !important;
        }

    }

    /* Forms
    --------- */

    .share {
        .mat-mdc-input-element {
            color: mat.m2-get-color-from-palette($primary, 400) !important;
        }
    }
    
    /* Utilities 
    ------------- */

    .as-split-gutter {
        border-top-color: $md-as-split-border;
        background-color: $md-as-split-bg !important;
    }

    ::-webkit-scrollbar-track { 
        background-color: mat.m2-get-color-from-palette($primary, 200) !important;
    }
    .md-popup ::-webkit-scrollbar-track { 
        background-color: mat.m2-get-color-from-palette($primary, 100) !important;
    }
    ::-webkit-scrollbar-thumb {
        background-color: mat.m2-get-color-from-palette($primary) !important;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: mat.m2-get-color-from-palette($primary, 600) !important;
    }


    /* ANGULAR MDC MIGRATION STYLEES FIX */

    .mat-mdc-checkbox label{
        font-size: 11px;
    }
    .mat-mdc-select{
        font-size: 11px !important;
    }


    .mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
        font-size: 11px !important;
    }
    .mat-mdc-select-panel{
        z-index: 2 !important;
    }
    .mdc-checkbox{
        padding: 8px !important;
        height: 14px !important;
        width: 14px !important;
        flex: 0 0 14px !important;
    }
    .mat-mdc-menu-item{
        min-height: 34px !important;
    }
    .mat-mdc-option{
        min-height: 34px;
        font-size: 11px !important;
    }
    .mat-mdc-select-placeholder{
        -webkit-text-fill-color: none !important;
    }
    .mat-mdc-floating-label{
        display: flex !important;
    }
    .mat-mdc-text-field-wrapper{
        background-color: $background !important;
        font-size: 11px;
    }
    .mat-mdc-form-field{
        padding-top: 2px;
        padding-bottom: 2px;
    }
    
    .mat-pseudo-checkbox-checked, .mdc-checkbox__mixedmark, .mdc-checkbox__checkmark{
        color: white !important;
    }
    .mat-mdc-input-element{
        font-size: 11px !important;
    }
    .mat-mdc-tab
    {
        min-width: 0px !important;
        max-width: 100px !important;
    }
    .mat-mdc-menu-panel{
        z-index: 2 !important;
    }
    .mat-mdc-dialog-surface{
        padding: 14px !important;
    }
    .mat-mdc-tooltip{
        background-color: $background !important;
    }
    .progress-control{
        color: $md-popup-header-color;
        .progress-text{
            color: $md-popup-header-color;
        }
    }
}